var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main" },
    [
      _c("van-search", {
        staticClass: "van-main-search",
        attrs: {
          shape: "round",
          "left-icon": "",
          "show-action": "",
          placeholder: "可选择发票种类",
          "input-align": _vm.constants.input_align,
          "label-width": _vm.constants.labelWidth
        },
        on: {
          click: function($event) {
            return _vm.fieldClick()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function() {
              return [
                _c("div", { on: { click: _vm.onSearch } }, [_vm._v(" 搜索 ")])
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.searchForm.distinguishName,
          callback: function($$v) {
            _vm.$set(_vm.searchForm, "distinguishName", $$v)
          },
          expression: "searchForm.distinguishName"
        }
      }),
      _c(
        "div",
        { staticClass: "van-refresh-list" },
        [
          _vm.utils.checkSystemType(_vm.constants.systemType.gzbSystem)
            ? _c(
                "van-tabs",
                {
                  model: {
                    value: _vm.active,
                    callback: function($$v) {
                      _vm.active = $$v
                    },
                    expression: "active"
                  }
                },
                [
                  _c(
                    "van-tab",
                    { attrs: { title: "销项发票" } },
                    [
                      _c(
                        "van-pull-refresh",
                        {
                          on: { refresh: _vm.onRefresh },
                          model: {
                            value: _vm.refreshing,
                            callback: function($$v) {
                              _vm.refreshing = $$v
                            },
                            expression: "refreshing"
                          }
                        },
                        [
                          _c(
                            "van-list",
                            {
                              attrs: {
                                finished: _vm.finished,
                                "finished-text": _vm.finishedText
                              },
                              on: { load: _vm.onLoad },
                              model: {
                                value: _vm.loading,
                                callback: function($$v) {
                                  _vm.loading = $$v
                                },
                                expression: "loading"
                              }
                            },
                            _vm._l(
                              this._.filter(_vm.tableList, { distinguish: 1 }),
                              function(item) {
                                return _c(
                                  "van-cell-group",
                                  {
                                    key: item.id,
                                    staticClass: "van-hairline-bottom"
                                  },
                                  [
                                    _c(
                                      "van-radio-group",
                                      {
                                        model: {
                                          value: _vm.radio,
                                          callback: function($$v) {
                                            _vm.radio = $$v
                                          },
                                          expression: "radio"
                                        }
                                      },
                                      [
                                        _c("van-cell", {
                                          attrs: {
                                            title: "开票方：",
                                            value: item.invoiceSendName
                                          }
                                        }),
                                        _c("van-cell", {
                                          attrs: {
                                            title: "收票方：",
                                            value: item.invoiceReceiveName
                                          }
                                        }),
                                        _c("van-cell", {
                                          attrs: {
                                            title: "开票金额（元）：",
                                            value: _vm.utils.moneyFormat(
                                              item.invoiceMoney
                                            )
                                          }
                                        }),
                                        _c("van-cell", {
                                          attrs: {
                                            title: "开票日期：",
                                            value: item.invoiceDate
                                          }
                                        }),
                                        _c("van-radio", {
                                          staticClass: "radio",
                                          attrs: { name: item.id },
                                          on: {
                                            click: function($event) {
                                              return _vm.select(item)
                                            }
                                          }
                                        }),
                                        _c(
                                          "van-cell",
                                          [
                                            _c(
                                              "van-button",
                                              {
                                                staticClass: "lf",
                                                attrs: {
                                                  color: "#1373CC",
                                                  plain: "",
                                                  round: "",
                                                  size: "small"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.$router.push({
                                                      name: "invoiceDetail",
                                                      query: {
                                                        id: item.invoiceId
                                                      }
                                                    })
                                                  }
                                                }
                                              },
                                              [_vm._v(" 查看详情 ")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "van-tab",
                    { attrs: { title: "进项发票" } },
                    [
                      _c(
                        "van-pull-refresh",
                        {
                          on: { refresh: _vm.onRefresh },
                          model: {
                            value: _vm.refreshing,
                            callback: function($$v) {
                              _vm.refreshing = $$v
                            },
                            expression: "refreshing"
                          }
                        },
                        [
                          _c(
                            "van-list",
                            {
                              attrs: {
                                finished: _vm.finished,
                                "finished-text": _vm.finishedText
                              },
                              on: { load: _vm.onLoad },
                              model: {
                                value: _vm.loading,
                                callback: function($$v) {
                                  _vm.loading = $$v
                                },
                                expression: "loading"
                              }
                            },
                            _vm._l(
                              this._.filter(_vm.tableList, { distinguish: 2 }),
                              function(item) {
                                return _c(
                                  "van-cell-group",
                                  {
                                    key: item.id,
                                    staticClass: "van-hairline-bottom"
                                  },
                                  [
                                    _c("van-cell", {
                                      attrs: {
                                        title: "开票方：",
                                        value: item.invoiceSendName
                                      }
                                    }),
                                    _c("van-cell", {
                                      attrs: {
                                        title: "收票方：",
                                        value: item.invoiceReceiveName
                                      }
                                    }),
                                    _c("van-cell", {
                                      attrs: {
                                        title: "开票金额（元）：",
                                        value: _vm.utils.moneyFormat(
                                          item.invoiceMoney
                                        )
                                      }
                                    }),
                                    _c("van-cell", {
                                      attrs: {
                                        title: "开票日期：",
                                        value: item.invoiceDate
                                      }
                                    }),
                                    _c(
                                      "van-cell",
                                      [
                                        _c(
                                          "van-button",
                                          {
                                            staticClass: "lf",
                                            attrs: {
                                              color: "#1373CC",
                                              plain: "",
                                              round: "",
                                              size: "small"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.$router.push({
                                                  name: "invoiceDetail",
                                                  query: { id: item.invoiceId }
                                                })
                                              }
                                            }
                                          },
                                          [_vm._v(" 查看详情 ")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "van-tab",
                    { attrs: { title: "全部" } },
                    [
                      _c(
                        "van-pull-refresh",
                        {
                          on: { refresh: _vm.onRefresh },
                          model: {
                            value: _vm.refreshing,
                            callback: function($$v) {
                              _vm.refreshing = $$v
                            },
                            expression: "refreshing"
                          }
                        },
                        [
                          _c(
                            "van-list",
                            {
                              attrs: {
                                finished: _vm.finished,
                                "finished-text": _vm.finishedText
                              },
                              on: { load: _vm.onLoad },
                              model: {
                                value: _vm.loading,
                                callback: function($$v) {
                                  _vm.loading = $$v
                                },
                                expression: "loading"
                              }
                            },
                            _vm._l(_vm.tableList, function(item) {
                              return _c(
                                "van-cell-group",
                                {
                                  key: item.id,
                                  staticClass: "van-hairline-bottom"
                                },
                                [
                                  _c("van-cell", {
                                    attrs: {
                                      title: "开票方：",
                                      value: item.invoiceSendName
                                    }
                                  }),
                                  _c("van-cell", {
                                    attrs: {
                                      title: "收票方：",
                                      value: item.invoiceReceiveName
                                    }
                                  }),
                                  _c("van-cell", {
                                    attrs: {
                                      title: "开票金额（元）：",
                                      value: _vm.utils.moneyFormat(
                                        item.invoiceMoney
                                      )
                                    }
                                  }),
                                  _c("van-cell", {
                                    attrs: {
                                      title: "开票日期：",
                                      value: item.invoiceDate
                                    }
                                  }),
                                  _c(
                                    "van-cell",
                                    [
                                      _c(
                                        "van-button",
                                        {
                                          staticClass: "lf",
                                          attrs: {
                                            color: "#1373CC",
                                            plain: "",
                                            round: "",
                                            size: "small"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.$router.push({
                                                name: "invoiceDetail",
                                                query: { id: item.invoiceId }
                                              })
                                            }
                                          }
                                        },
                                        [_vm._v(" 查看详情 ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "van-list",
                    {
                      attrs: {
                        finished: _vm.finished,
                        "finished-text": _vm.finishedText
                      },
                      on: { load: _vm.onLoad },
                      model: {
                        value: _vm.loading,
                        callback: function($$v) {
                          _vm.loading = $$v
                        },
                        expression: "loading"
                      }
                    },
                    _vm._l(_vm.tableList, function(item) {
                      return _c(
                        "van-cell-group",
                        { key: item.id, staticClass: "van-hairline-bottom" },
                        [
                          _c("van-cell", {
                            attrs: {
                              title: "发票种类：",
                              value: _vm.utils.statusFormat(
                                String(item.distinguish),
                                "invoiceType"
                              )
                            }
                          }),
                          _c("van-cell", {
                            attrs: {
                              title: "开票方：",
                              value: item.invoiceSendName
                            }
                          }),
                          _c("van-cell", {
                            attrs: {
                              title: "收票方：",
                              value: item.invoiceReceiveName
                            }
                          }),
                          _c("van-cell", {
                            attrs: {
                              title: "开票金额（元）：",
                              value: _vm.utils.moneyFormat(item.invoiceMoney)
                            }
                          }),
                          _c("van-cell", {
                            attrs: {
                              title: "开票日期：",
                              value: item.invoiceDate
                            }
                          }),
                          _c(
                            "van-cell",
                            [
                              _c(
                                "van-button",
                                {
                                  staticClass: "lf",
                                  attrs: {
                                    color: "#1373CC",
                                    plain: "",
                                    round: "",
                                    size: "small"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push({
                                        name: "invoiceDetail",
                                        query: { id: item.invoiceId }
                                      })
                                    }
                                  }
                                },
                                [_vm._v(" 查看详情 ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
        ],
        1
      ),
      _vm.utils.checkSystemType(_vm.constants.systemType.gzbSystem)
        ? _c(
            "van-row",
            { staticClass: "fixed-btn-bottom", attrs: { gutter: "20" } },
            [
              _vm.active === 0
                ? _c(
                    "van-button",
                    {
                      attrs: {
                        disabled: _vm.selectRow.status != 3,
                        color: "#1373CC",
                        block: "",
                        type: "primary"
                      },
                      on: {
                        click: function($event) {
                          return _vm.add()
                        }
                      }
                    },
                    [_vm._v(" 添加开票结果 ")]
                  )
                : _vm._e(),
              _vm.active === 1
                ? _c(
                    "van-button",
                    {
                      attrs: { color: "#1373CC", block: "", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.$router.push({
                            name: "invoicePlatformEdit"
                          })
                        }
                      }
                    },
                    [_vm._v(" 新增发票记录 ")]
                  )
                : _vm._e(),
              _vm.active === 2
                ? _c(
                    "van-button",
                    {
                      attrs: { color: "#1373CC", block: "", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.$router.back()
                        }
                      }
                    },
                    [_vm._v(" 返回 ")]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.utils.checkSystemType(_vm.constants.systemType.nongtouSystem)
        ? _c(
            "van-row",
            { staticClass: "fixed-btn-bottom", attrs: { gutter: "20" } },
            [
              _c(
                "van-col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "van-button",
                    {
                      attrs: { color: "#1373CC", block: "", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.$router.push({
                            name: "invoicePlatformEdit"
                          })
                        }
                      }
                    },
                    [_vm._v(" 新增发票记录 ")]
                  )
                ],
                1
              ),
              _c(
                "van-col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "van-button",
                    {
                      attrs: { color: "#1373CC", block: "", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.$router.back()
                        }
                      }
                    },
                    [_vm._v(" 返回 ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "van-popup",
        {
          attrs: { round: "", position: "bottom" },
          model: {
            value: _vm.dialog.selectDialog,
            callback: function($$v) {
              _vm.$set(_vm.dialog, "selectDialog", $$v)
            },
            expression: "dialog.selectDialog"
          }
        },
        [
          _c("van-picker", {
            attrs: {
              "show-toolbar": "",
              columns: _vm.columns,
              "value-key": _vm.valueKey
            },
            on: {
              cancel: function($event) {
                _vm.dialog.selectDialog = false
              },
              confirm: _vm.confirmPicker
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
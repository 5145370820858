<template>
  <div class="van-main">
    <van-search v-model="searchForm.distinguishName" class="van-main-search" shape="round" left-icon="" show-action placeholder="可选择发票种类" :input-align="constants.input_align" :label-width="constants.labelWidth" @click="fieldClick()"
    >
      <template #action>
        <div @click="onSearch">
          搜索
        </div>
      </template>
    </van-search>
    <div class="van-refresh-list">
      <van-tabs v-if="utils.checkSystemType(constants.systemType.gzbSystem)" v-model="active">
        <van-tab title="销项发票">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="finishedText"
              @load="onLoad"
            >
              <van-cell-group v-for="item in this._.filter(tableList, { distinguish: 1 })" :key="item.id" class="van-hairline-bottom">
                <van-radio-group v-model="radio">
                  <van-cell title="开票方：" :value="item.invoiceSendName" />
                  <van-cell title="收票方：" :value="item.invoiceReceiveName" />
                  <van-cell title="开票金额（元）：" :value="utils.moneyFormat(item.invoiceMoney)" />
                  <van-cell title="开票日期：" :value="item.invoiceDate" />
                  <van-radio :name="item.id" class="radio" @click="select(item)" />
                  <van-cell>
                    <van-button class="lf" color="#1373CC" plain round size="small" @click="$router.push({ name: 'invoiceDetail', query: { id: item.invoiceId } })">
                      查看详情
                    </van-button>
                  </van-cell>
                </van-radio-group>
              </van-cell-group>
            </van-list>
          </van-pull-refresh>
        </van-tab>
        <van-tab title="进项发票">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="finishedText"
              @load="onLoad"
            >
              <van-cell-group v-for="item in this._.filter(tableList, { distinguish: 2 })" :key="item.id" class="van-hairline-bottom">
                <van-cell title="开票方：" :value="item.invoiceSendName" />
                <van-cell title="收票方：" :value="item.invoiceReceiveName" />
                <van-cell title="开票金额（元）：" :value="utils.moneyFormat(item.invoiceMoney)" />
                <van-cell title="开票日期：" :value="item.invoiceDate" />
                <van-cell>
                  <van-button class="lf" color="#1373CC" plain round size="small" @click="$router.push({ name: 'invoiceDetail', query: { id: item.invoiceId } })">
                    查看详情
                  </van-button>
                </van-cell>
              </van-cell-group>
            </van-list>
          </van-pull-refresh>
        </van-tab>
        <van-tab title="全部">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="finishedText"
              @load="onLoad"
            >
              <van-cell-group v-for="item in tableList" :key="item.id" class="van-hairline-bottom">
                <van-cell title="开票方：" :value="item.invoiceSendName" />
                <van-cell title="收票方：" :value="item.invoiceReceiveName" />
                <van-cell title="开票金额（元）：" :value="utils.moneyFormat(item.invoiceMoney)" />
                <van-cell title="开票日期：" :value="item.invoiceDate" />
                <van-cell>
                  <van-button class="lf" color="#1373CC" plain round size="small" @click="$router.push({ name: 'invoiceDetail', query: { id: item.invoiceId } })">
                    查看详情
                  </van-button>
                </van-cell>
              </van-cell-group>
            </van-list>
          </van-pull-refresh>
        </van-tab>
      </van-tabs>
      <div v-else>
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="finishedText"
          @load="onLoad"
        >
          <van-cell-group v-for="item in tableList" :key="item.id" class="van-hairline-bottom">
            <van-cell title="发票种类：" :value="utils.statusFormat(String(item.distinguish), 'invoiceType')" />
            <van-cell title="开票方：" :value="item.invoiceSendName" />
            <van-cell title="收票方：" :value="item.invoiceReceiveName" />
            <van-cell title="开票金额（元）：" :value="utils.moneyFormat(item.invoiceMoney)" />
            <van-cell title="开票日期：" :value="item.invoiceDate" />
            <van-cell>
              <van-button class="lf" color="#1373CC" plain round size="small" @click="$router.push({ name: 'invoiceDetail', query: { id: item.invoiceId } })">
                查看详情
              </van-button>
            </van-cell>
          </van-cell-group>
        </van-list>
      </div>
    </div>
    <van-row class="fixed-btn-bottom" gutter="20" v-if="utils.checkSystemType(constants.systemType.gzbSystem)">
      <van-button :disabled="selectRow.status != 3" v-if="active === 0" color="#1373CC" block type="primary" @click="add()">
        添加开票结果
      </van-button>
      <van-button v-if="active === 1" color="#1373CC" block type="primary" @click="$router.push({name:'invoicePlatformEdit'})">
        新增发票记录
      </van-button>
      <van-button v-if="active === 2" color="#1373CC" block type="primary" @click="$router.back()">
        返回
      </van-button>
    </van-row>
    <van-row class="fixed-btn-bottom" gutter="20" v-if="utils.checkSystemType(constants.systemType.nongtouSystem)">
      <van-col span="12">
      <van-button color="#1373CC" block type="primary" @click="$router.push({name:'invoicePlatformEdit'})">
        新增发票记录
      </van-button>
      </van-col>
      <van-col span="12">
        <van-button color="#1373CC" block type="primary" @click="$router.back()">
          返回
        </van-button>
        </van-col>
    </van-row>
    <!--下拉弹层-->
    <van-popup v-model="dialog.selectDialog" round position="bottom">
      <van-picker show-toolbar :columns="columns" :value-key="valueKey" @cancel="dialog.selectDialog = false"
                  @confirm="confirmPicker"
      />
    </van-popup>
  </div>
</template>

<script>
import { List, PullRefresh, Cell, CellGroup, Search, Button, Col, Row, Tab, Tabs, RadioGroup, Radio, Popup, Picker } from 'vant'
export default {
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [Button.name]: Button,
    [Col.name]: Col,
    [Row.name]: Row,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Popup.name]: Popup,
    [Picker.name]: Picker
  },
  data () {
    return {
      searchForm: {
        invoiceSendName: ''
      },
      radio: null,
      active: 0,
      finishedText: '没有更多了',
      page: 1,
      tableList: [
      ],
      selectRow: {},
      loading: false,
      finished: false,
      refreshing: false,
      columns: [],
      valueKey: '',
      dialog: {
        selectDialog: false // 控制下拉弹层
      }
    }
  },
  created () {
    this.constants.invoiceType.unshift({
      value: '', label: '全部'
    })
  },
  methods: {
    onSearch () {
      this.page = 1
      this.tableList = []
      this.onLoad()
    },
    onLoad () {
      this.searchForm.pageNum = this.page
      this.api.contract.invoice.pageList(this.searchForm).then(result => {
        const resData = this._.cloneDeep(result.data.value)
        this.page = resData.pageNum + 1
        if (this.refreshing) {
          this.tableList = []
          this.refreshing = false
        }
        if (resData.pages === 0 && this._.isEmpty(resData.list)) {
          this.finishedText = '暂无数据'
          this.finished = true
        } else {
          this.loading = false
          this.tableList = this._.concat(this.tableList, resData.list)
          if (resData.pageNum === resData.pages) {
            this.finished = true
            this.finishedText = '没有更多了'
          }
        }
      }).catch(() => {
        this.finished = true
        this.refreshing = false
        this.finishedText = '暂无数据'
      })
    },
    onRefresh () {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.page = 1
      this.onLoad()
    },
    select (row) {
      this.selectRow = row
    },
    add () {
      this.$router.push({ name: 'invoicePlatformEdit', query: { put: 'put', id: this.selectRow.invoiceId } })
    },
    fieldClick (list) {
      list = 'invoiceType'
      this.listType = list
      this.dialog.selectDialog = true
      this.columns = this.constants.invoiceType
      const codeMapName = {
        invoiceType: 'label'
      }
      this.valueKey = codeMapName[list]
    },
    // 下拉弹层确定
    confirmPicker (value) {
      console.log(value)
      this.dialog.selectDialog = false
      this.searchForm.distinguish = value.value
      this.searchForm.distinguishName = value.label
      this.showPicker = false
    }
  }
}
</script>
<style lang="less" scoped>
.radio {
      position: absolute;
      left: 0px;
      top:calc(50% - 15px);
    }
.van-cell {
    padding: 0.26667rem 0.8rem;
}
.fixed-btn-bottom {
  background-color: #FFF;
}
</style>
